import { ClassNames } from '@emotion/core';
import { Button, Col, Drawer, Icon, Menu, Row, Tooltip } from 'antd';
import { Link, Logo, Responsive } from 'components';
import config from 'config';
import { withTranslate } from 'helpers';
import { withRouter } from 'react-router-dom';
import { media } from 'utils';
import { Component, compose, connect, PropTypes, React, styled } from 'utils/create';
import { colors } from 'utils/variables';

const drawerStyle = {
  '& .ant-drawer-body': {
    margin: 0,
    padding: 0,
  },
  '& .ant-drawer-header': {
    height: config.ui.headerHeightPx,
  },
  '& .ant-drawer-title': {
    padding: '5px 0',
  },
  '& .ant-drawer-close': {
    padding: '4px',
  },
};

const toRight = {
  float: 'right',

  '& li': {
    marginBottom: 0,
  },
};

const MobileCol = styled(Col)({
  display: 'flex !important',
  alignItems: 'center',
});

const MobileTitle = styled.span(({ transparent }) => ({
  fontWeight: 'bold',
  textShadow: transparent ? `0px 0px 5px ${colors.black}` : '',
  flex: 1,
}));

const MobileLogo = styled(Logo)({
  width: '50%',
  margin: '3rem auto',
});

const Hamburger = styled(({ visible, transparent, ...props }) => (
  <Button icon={visible ? 'menu-fold' : 'menu-unfold'} {...props} />
))(({ transparent }) =>
  transparent
    ? {
        color: `${colors.white} !important`,
        borderColor: `${colors.white} !important`,
        backgroundColor: `${colors.darkGray} !important`,
      }
    : {
        color: `${colors.darkGray} !important`,
        borderColor: `${colors.darkGray} !important`,
      },
);

class Header extends Component {
  state = {
    drawerVisible: false,
  };

  toggleDrawer = () => {
    const { drawerVisible } = this.state;
    this.setState({ drawerVisible: !drawerVisible });
  };

  render() {
    const { drawerVisible } = this.state;
    const {
      _,
      location: { pathname },
      transparentHeader,
    } = this.props;

    const menuItem = ({ path, external = false, key, icon, tooltip }) => (
      <Menu.Item key={path}>
        {tooltip ? (
          <Tooltip title={_(`menu.${key}`)} placement="bottom">
            <Link to={path} external={external}>
              {icon && <Icon type={icon} />}
            </Link>
          </Tooltip>
        ) : (
          <Link to={path} external={external}>
            {icon && <Icon type={icon} />}
            {_(`menu.${key}`)}
          </Link>
        )}
      </Menu.Item>
    );

    const menu = params => (
      <Menu {...params} selectedKeys={[pathname]} onClick={this.toggleDrawer}>
        {config.menu.map(({ children, ...rest }) =>
          children ? (
            <Menu.SubMenu title={_(`menu.${rest.key}`)}>
              {children.map(child => menuItem(child))}
            </Menu.SubMenu>
          ) : (
            menuItem(rest)
          ),
        )}
        <Menu.Item key="facebook">
          <Tooltip title="Facebook" placement="bottom">
            <a href="https://www.facebook.com/Archeologia-Bagicza-292994937986896">
              <Icon type="facebook" theme="filled" />
            </a>
          </Tooltip>
        </Menu.Item>
      </Menu>
    );

    return (
      <ClassNames>
        {({ css }) => (
          <div>
            <Row type="flex" align="middle">
              <Col xs={0} md={24}>
                {menu({
                  mode: 'horizontal',
                  theme: transparentHeader ? 'dark' : 'light',
                  className: css(toRight),
                })}
              </Col>
              <Col xs={24} md={0}>
                <MobileCol>
                  <MobileTitle transparent={transparentHeader}>Archeologia Bagicza</MobileTitle>
                  <Hamburger
                    onClick={this.toggleDrawer}
                    visible={drawerVisible}
                    transparent={transparentHeader}
                  />
                </MobileCol>
              </Col>
            </Row>

            <Responsive maxWidth={media.md}>
              <Drawer
                placement="left"
                getContainer="#root"
                onClose={this.toggleDrawer}
                visible={drawerVisible}
                wrapClassName={css(drawerStyle)}
              >
                <MobileLogo color={colors.lightGray} />
                {menu({ theme: 'light' })}
              </Drawer>
            </Responsive>
          </div>
        )}
      </ClassNames>
    );
  }
}

Header.propTypes = {
  _: PropTypes.func.isRequired,
  location: PropTypes.location.isRequired,
  transparentHeader: PropTypes.bool,
};

Header.defaultProps = {
  transparentHeader: true,
};

const mapStateToProps = state => ({
  transparentHeader: state.getIn(['ui', 'transparentHeader']),
});

export default compose(
  withRouter,
  withTranslate,
  connect(mapStateToProps),
)(Header);
