import mockBadania from 'mocks/badania.yml';
import { api } from 'utils';
import * as types from './types';

const mock = id =>
  new Promise(resolve => {
    switch (id) {
      case 'badania':
        return resolve(mockBadania);
      default:
        return resolve({});
    }
  });

export const fetchPages = () => ({
  type: types.PAGES_LIST,
  payload: api.get('/pages'),
});

export const fetchPage = id => ({
  type: types.PAGES_GET,
  payload: mock(id),
  meta: { id },
});

export const getPage = state => id => state.getIn(['pages', id, 'data']);
