import { Card, Col, Row } from 'antd';
import { Page } from 'components';
import mockedNewsList from 'mocks/news.yml';
import LinesEllipsis from 'react-lines-ellipsis';
import { withRouter } from 'react-router-dom';
import { compose, connect, styled, PropTypes, React } from 'utils/create';
import { colors } from 'utils/variables';

const StyledCard = styled(Card)({
  '&.ant-card': {
    backgroundColor: colors.gray,
    margin: '1rem 0',
  },
});

const NewsTitle = styled.p({
  fontWeight: 'bold',
});

const NewsImage = styled.img({
  margin: 0,
});

const More = styled.p({
  textAlign: 'right',
  color: colors.lightGray,
});

const NewsPage = ({ history, newsList }) => (
  <Page>
    <h1>Aktualności</h1>

    <Row type="flex" justify="center" gutter={8}>
      {newsList.map(news => (
        <Col sm={8} key={news.slug}>
          <StyledCard
            hoverable
            cover={<NewsImage alt={news.title} src={news.featured} />}
            onClick={() => history.push(`/aktualnosci/${news.slug}`)}
          >
            <div>
              <NewsTitle>{news.title}</NewsTitle>
              {news.excerpt ? (
                <p>{news.excerpt}</p>
              ) : (
                <LinesEllipsis text={news.content} maxLine={4} component="p" />
              )}
              <More>Czytaj dalej...</More>
            </div>
          </StyledCard>
        </Col>
      ))}
    </Row>
  </Page>
);

NewsPage.propTypes = {
  history: PropTypes.history.isRequired,
  newsList: PropTypes.array, // eslint-disable-line
};

NewsPage.defaultProps = {
  newsList: [],
};

const mapStateToProps = () => ({
  newsList: mockedNewsList,
});

export default compose(
  withRouter,
  connect(mapStateToProps),
)(NewsPage);
