import { Button, Card, Col, Row } from 'antd';
import { Gallery, Logo } from 'components';
import config from 'config';
import newsList from 'mocks/news.yml';
import LinesEllipsis from 'react-lines-ellipsis';
import { withRouter } from 'react-router-dom';
import { scroller } from 'react-scroll';
import { media } from 'utils';
import { Component, compose, styled, PropTypes, React } from 'utils/create';
import { colors } from 'utils/variables';
import assets, { logos } from './assets';
import background from './assets/background.jpg';
import Section from './Section';

const HomePageWrapper = styled.div({
  overflow: 'hidden',
});

const Splash = styled.header({
  height: '100vh',
  backgroundImage: `url("${background}")`,
  backgroundPosition: `center center`,
  backgroundSize: 'cover',
  textAlign: 'center',
  justifyContent: 'center',
  display: 'flex',
  flexDirection: 'column',

  [media.query.mdAndUp]: {
    padding: '4rem 2rem 0 50%',
  },
});

const StyledLogo = styled(Logo)({
  width: '100%',
  textAlign: 'center',

  [media.query.mdAndUp]: {
    textAlign: 'right',
  },

  '& svg': {
    width: '13rem',
    margin: '1rem 3rem',
  },
});

const Title = styled.h1({
  textTransform: 'uppercase',
  textAlign: 'center',
  fontSize: '2rem',

  '& big': {
    display: 'block',
    fontSize: '3.5rem',
  },

  [media.query.mdAndUp]: {
    textAlign: 'right',
    fontSize: '2.4rem',

    '& big': {
      fontSize: '4rem',
    },
  },
});

const Subtitle = styled.h2({
  textAlign: 'center',
  fontSize: '1rem',
  margin: '1rem 0',

  [media.query.mdAndUp]: {
    textAlign: 'right',
    margin: 0,
  },
});

const ButtonWrapper = styled.div({
  textAlign: 'center',
  marginTop: '1rem',

  [media.query.mdAndUp]: {
    textAlign: 'right',
    marginTop: '2rem',
  },
});

const StyledCard = styled(Card)({
  '&.ant-card': {
    backgroundColor: colors.gray,
    margin: '1rem 0',
  },
});

const NewsTitle = styled.p({
  fontWeight: 'bold',
});

const NewsImage = styled.img({
  margin: 0,
});

const More = styled.p({
  textAlign: 'right',
  color: colors.lightGray,
});

const MoreNews = styled.p({
  textAlign: 'center',

  '& span': {
    cursor: 'pointer',
    fontWeight: 'bold',
    border: `1px solid ${colors.white}`,
    display: 'inline-block',
    margin: '2rem 0',
    padding: '1rem',
    opacity: 0.5,

    '&:hover': {
      opacity: 1,
      borderStyle: 'dashed',
    },
  },
});

const Logos = styled(Row)({
  '& img': {
    maxWidth: '100%',
    padding: '3rem',
  },
});
const LogosText = styled.p({
  color: colors.lightGray,
  fontSize: '0.7rem',
});

class HomePage extends Component {
  onSplashArrowClick = () => {
    scroller.scrollTo('news', {
      duration: 1000,
      smooth: true,
      offset: -config.ui.headerHeight,
    });
  };

  render() {
    const { history } = this.props;

    return (
      <HomePageWrapper>
        <Splash>
          <StyledLogo />
          <Title>
            Archeologia <big>Bagicza</big>
          </Title>
          <Subtitle>Badania nieinwazyjne w lesie i na plaży</Subtitle>
          <ButtonWrapper>
            <Button
              size="large"
              type="secondary"
              shape="circle"
              icon="arrow-down"
              onClick={this.onSplashArrowClick}
            />
          </ButtonWrapper>
        </Splash>

        <Section id="news">
          <h1>Aktualności</h1>

          <Row type="flex" justify="center" gutter={8}>
            {newsList.slice(0, 3).map(news => (
              <Col sm={8} key={news.slug}>
                <StyledCard
                  hoverable
                  cover={<NewsImage alt={news.title} src={news.featured} />}
                  onClick={() => history.push(`/aktualnosci/${news.slug}`)}
                >
                  <div>
                    <NewsTitle>{news.title}</NewsTitle>
                    {news.excerpt ? (
                      <p>{news.excerpt}</p>
                    ) : (
                      <LinesEllipsis text={news.content} maxLine={4} component="p" />
                    )}
                    <More>Czytaj dalej...</More>
                  </div>
                </StyledCard>
              </Col>
            ))}
          </Row>
          <MoreNews onClick={() => history.push('/aktualnosci')}>
            <span>Zobacz wszystkie wpisy</span>
          </MoreNews>
        </Section>

        <Section id="about">
          <h1>O projekcie</h1>
          <p>
            Badania archeologiczne na terenach leśnych są niezwykle trudne z uwagi na problemy,
            jakich nastręcza bujna roślinność. Niemniej od momentu wykorzystania w badaniach
            archeologicznych technologii LIDAR coraz więcej stanowisk posiadających własną formę
            terenową zostaje rozpoznanych na obszarach pokrytych gęstą roślinnością.
          </p>
          <p>
            Początki projektu sięgają 2016 roku, kiedy w kołobrzeskich lasach rozpoznano
            cmentarzysko kurhanowe (Bagicz stan. 22). Znaczna część informacji na jego temat
            pochodziła z analizy map wykonanych w technologii LIDAR, do których przygotowania
            wykorzystano dane pochodzące z ISOK (Informatyczny System Osłony Kraju przed
            nadzwyczajnymi zagrożeniami). Prace wykopaliskowe, które miały na celu określenie
            chronologii prowadzono latem 2017 roku. Badania pozwoliły określić przynależność
            kulturową cmentarzyska do kultury wielbarskiej.
          </p>

          <Gallery images={assets.filter(asset => asset.tags.includes('o-projekcie'))} />

          <p>
            W trakcie wykopalisk lokalni pasjonaci historii oraz mgr Piotr Agaciak, leśniczy
            opiekujący się lasem, wskazywali na wiele innych potencjalnych stanowisk
            archeologicznych mogących znajdować się w okolicy. Dodatkowo z miejscowości Bagicz znane
            są jeszcze dwa stanowiska wielbarskie. Stanowisko nr 1 jest o tyle specyficzne, że
            stanowi odosobniony pochówek szkieletowy w kłodzie drewnianej, który ponad sto lat temu
            znaleziono pod klifem w miejscowości Bagicz. To właśnie z nim kojarzone jest osadnictwo
            kultury wielbarskiej nad rzeką Parsętą w dyskursie naukowym. W odległości ok. 1 km od
            stanowiska 1 znajduje się stanowisko 2, na którym w trakcie badań ratowniczych
            prowadzonych przez O. Dibelta w latach 1936-37 zarejestrowano 4 groby ciałopalne, z
            który 3 datowano na okres wpływów rzymskich i jeden na okres przedrzymski. Omawiany
            teren wydaje się mieć niezwykły potencjał poznawczy (zob. Wołągiewicz 1986).
          </p>
        </Section>

        <Section light>
          <h1>Badania w lesie</h1>
          <p>
            Celem niniejszego projektu jest nieinwazyjne, archeologiczne rozpoznanie terenów leśnych
            miedzy miejscowościami Kądzielno, Stramniczka i Bagicz w okolicy Kołobrzegu, a także
            badania powierzchniowe na terenie plaży morskiej w okolicy lotniska w Bagiczu. Niestety
            las jest aktywnie penetrowany przez detektorystów, na co wskazują zniszczenia odnotowane
            na kurhanach, a także informacje od leśniczego. Dodatkowo na jego terenie prowadzone są
            intensywne prace leśne. Część nasypów na stanowisku wielbarskim uległa istotnemu
            naruszeniu właśnie w ich wyniku. Dlatego też tak ważne wydaje się zadokumentowanie
            stanowisk archeologicznych w omawianym regionie.
          </p>

          <Gallery images={assets.filter(asset => asset.tags.includes('las'))} />

          <p>
            Jednym z podstawowych elementów projektu będzie wykorzystanie efektów skanowania
            laserowego, które wniosło całkowicie nową, jakość w badaniach archeologicznych na
            terenach zalesionych. Wygenerowane w oparciu o dane pochodzące z LIDAR mapy
            zinterpretowane zostaną pod kątem obecności obiektów antropogenicznych.
          </p>
          <p>
            Na cmentarzysku kurhanowym kultury wielbarskiej przeprowadzone zostaną badania
            magnetyczne, które pozwolą wnieść nowe informacje na temat konstrukcji kurhanów bez
            konieczności prowadzenia badań wykopaliskowych.
          </p>
          <p>
            Weryfikacja terenowa stanowisk przeprowadzona zostanie w oparciu o dane pochodzące z
            LIDARa, ewentualne materiały archiwalne, ale również informacje od mieszkańców oraz
            pracowników nadleśnictwa. Dane na temat osadnictwa uzupełnione zostaną przez badania o
            charakterze paleośrodowiskowym, przede wszystkim badania palinologiczne. Dzięki temu
            możliwe stanie się osadzenie osadnictwa na omawianym terenie w kontekście środowiskowym.
          </p>
        </Section>
        <Section>
          <h1>Badania na klifie</h1>
          <p>
            W ramach projektu odbędą się również badania powierzchniowe w okolicach klifu w Bagiczu,
            które mają na celu zlokalizowanie stanowiska wielbarskiego wiązanego z pochówkiem
            kobiety w kłodzie drewnianej. W ramach projektu zrealizowane zostaną również ponowne
            badania antropologiczne szkieletu z Bagicza oraz jego analiza biochemiczna.
          </p>
          <p>
            Analiza biochemiczna, na którą składają się badania izotopów stałych strontu, azotu,
            węgla i tlenu z dwóch zębów pobranych ze szkieletu z Bagicza mogą pomóc odpowiedzieć na
            wiele pytań związanych z funkcjonowaniem mieszkańców omawianego obszaru w okresie
            wpływów rzymskich i osadzenia ich w kontekście środowiskowym.
          </p>
          <p>
            Rezultaty opisanych powyżej badań pozwolą na wnioskowanie na temat relacji pomiędzy
            nekropolą w Bagiczu (stan. 22), a innymi stanowiskami okresu wpływów rzymskich w
            mikroregionie.
          </p>

          <Gallery images={assets.filter(asset => asset.tags.includes('klif'))} />
        </Section>
        <Section light>
          <h1>Współpracują z nami</h1>
          <p>
            Realizowane przez nas badania nie miałyby szans na powodzenie gdyby nie pomoc zarówno
            badaczy z różnych instytucji naukowych, jak również pasjonatów historii z Kołobrzegu i
            okolic.
          </p>
          <p>
            Serdeczne podziękowania należą się zwłaszcza <strong>Nadleśnictwu Gościno</strong> oraz
            jego reprezentacji w postaci <strong>leśniczego Piotra Agaciaka</strong>, który wspiera
            nas swoją wiedzą na temat lasu i jego okolic.
          </p>
          <p>
            Wdzięczni jesteśmy również <strong>Muzeum Patria Colbergensis</strong> oraz jego
            reprezentacji w postaci <strong>Pana Roberta Maziarza</strong>, który podrzuca nam
            informacje na temat stanowisk z okolicy Kołobrzegu.
          </p>
          <p>
            Ogromnym wsparciem jest dla nas również współpraca z
            <strong> Grupą Eksploracyjno- Poszukiwawczą PARSĘTA</strong>. Umiejętności obsługi
            wykrywacza metali oraz poświęcanie swojego prywatnego czasu w celu uczestnictwa w
            projekcie są bardzo dużym wkładem w jego sukces.
          </p>
          <p>
            Serdecznie dziękujemy również Państwu{' '}
            <strong>Agnieszce i Stanisławowi Trzaskowskim</strong>, którzy udzielili zgody na
            prowadzenie badań na swoim terenie.
          </p>
        </Section>
        <Section veryLight>
          <Logos type="flex" align="middle" justify="center">
            <Col xs={6}>
              <img src={logos.mkdin} alt="Ministerstwo Kultury i Dziedzictwa Narodowego" />
              <LogosText>
                Dofinansowano ze środków Ministra Kultury i Dziedzictwa Narodowego pochodzących z
                Funduszu Promocji Kultury.
              </LogosText>
            </Col>
            <Col xs={6}>
              <img src={logos.nid} alt="Narodowy Instytut Dziedzictwa" />
            </Col>
            <Col xs={6}>
              <img src={logos.kaus} alt="Katedra Archeologii US" />
            </Col>
            <Col xs={6}>
              <img src={logos.us} alt="Uniwersytet Szczeciński" />
            </Col>
          </Logos>
        </Section>
      </HomePageWrapper>
    );
  }
}

HomePage.propTypes = {
  history: PropTypes.history.isRequired,
};

export default compose(withRouter)(HomePage);
