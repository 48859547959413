/* eslint-disable */
import { Markdown, Page } from 'components';
import { withTranslate } from 'helpers';
import { Map } from 'immutable';
import { withRouter } from 'react-router-dom';
import { fetchPage, getPage } from 'reducers/pages/actions';
import { Component, compose, connect, PropTypes, React } from 'utils/create';

class ContentPage extends Component {
  componentDidMount() {
    const { handleFetchPage, id } = this.props;
    handleFetchPage(id);
  }

  render() {
    const { _, page, loading } = this.props;

    console.log('page', page);
    console.log('loading', loading);
    return (
      <Page>
        <Markdown
          source={page.get('content') || ''}
          images={page.get('images') ? page.get('images').toJS() : {}}
        />
      </Page>
    );
  }
}

ContentPage.propTypes = {
  _: PropTypes.func.isRequired,
  location: PropTypes.location.isRequired,
  loading: PropTypes.bool,
  page: PropTypes.map,
  id: PropTypes.string,
};

ContentPage.defaultProps = {
  loading: true,
  page: Map(),
  id: null,
};

const mapStateToProps = (
  state,
  {
    match: {
      params: { id },
    },
  },
) => {
  console.log('id', state.getIn(['pages', id]));
  return {
    id,
    page: getPage(state)(id),
    loading: state.getIn(['pages', id, 'isLoading']),
  };
};

const mapDispatchToProps = dispatch => ({
  handleFetchPage: id => dispatch(fetchPage(id)),
});

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withRouter,
  withTranslate,
)(ContentPage);
