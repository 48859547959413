import { Link as BaseLink } from 'react-router-dom';
import { PropTypes, React } from 'utils/create';

const Link = ({ to, external, children, ...props }) =>
  to.startsWith('/') && !external ? (
    <BaseLink to={to} {...props}>
      {children}
    </BaseLink>
  ) : (
    <a href={to} {...props}>
      {children}
    </a>
  );

Link.propTypes = {
  to: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  external: PropTypes.bool,
};

Link.defaultProps = {
  external: false,
};

export default Link;
