import Typography from 'typography';
import alton from 'typography-theme-alton';

import { colors } from 'utils/variables';

const typography = new Typography(
  Object.assign(alton, {
    overrideThemeStyles: () => ({
      'h1, h2': {
        color: colors.text,
      },
      'p, table, li:not([role="menuitem"])': {
        color: colors.text,
        opacity: 0.75,
      },
      'thead': {
        fontWeight: 'bold'
      }
    }),
  }),
);

const family = typography.options.googleFonts
  .map(font => `${font.name.split(' ').join('+')}:${font.styles.join(',')}`)
  .join('|');

export const typographyFontsUrl = `//fonts.googleapis.com/css?family=${family}`;

export default typography;
