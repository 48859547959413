/* eslint-disable */

import kaus from './homepage_kaus.png';
import mkdin from './homepage_mkidn.png';
import nid from './homepage_nid.png';
import us from './homepage_us.png';

export const logos = { kaus, mkdin, nid, us };

export default [
  {
    original: require('./kurhan-na-stanowisku.jpg'),
    thumbnail: require('./kurhan-na-stanowisku_thumb.jpg'),
    width: 841,
    height: 631,
    description: 'Kurhan na stanowisku w Bagiczu w trakcie badań wykopaliskowych',
    tags: ['o-projekcie'],
  },
  {
    original: require('./Kurhan_skala_250.jpg'),
    thumbnail: require('./Kurhan_skala_250_thumb.jpg'),
    width: 1600,
    height: 1118,
    description: 'Kurhan skala 250',
    tags: ['o-projekcie'],
  },
  {
    original: require('./model_kataster_skala_2500.jpg'),
    thumbnail: require('./model_kataster_skala_2500_thumb.jpg'),
    width: 1600,
    height: 1131,
    description: 'model_kataster_skala_2500',
    tags: ['las'],
  },
  {
    original: require('./szkielet-z-bagicza.jpg'),
    thumbnail: require('./szkielet-z-bagicza_thumb.jpg'),
    width: 620,
    height: 716,
    description:
      'Pochówek kobiety w trumnie z kłody drewnianej, fot. E. Dokudowicz.ochówek kobiety w trumnie z kłody drewnianej, fot. E. Dokudowicz.',
    tags: ['klif'],
  },
  {
    original: require('./plaza.jpg'),
    thumbnail: require('./plaza_thumb.jpg'),
    width: 1600,
    height: 1200,
    description: 'plaza',
    tags: ['klif'],
  },
];
