import { fromJS } from 'immutable';
import typeToReducer from 'type-to-reducer';
import * as types from './types';

const initialState = fromJS({});

export default typeToReducer(
  {
    [types.PAGES_LIST]: {
      PENDING: state => state.merge({ isLoading: true, error: null }),
      REJECTED: (state, { error }) => state.merge({ isLoading: false, error }),
      FULFILLED: (state, { payload: { data } }) =>
        state.merge({ isLoading: false, error: null, data }),
    },
    [types.PAGES_GET]: {
      PENDING: (state, { meta: { id } }) => state.mergeIn([id], fromJS({ isLoading: true })),
      REJECTED: (state, { error }) => state.merge({ isLoading: false, error }),
      FULFILLED: (state, { payload: data, meta: { id } }) =>
        state.mergeIn([id], fromJS({ isLoading: false, data })),
    },
  },
  initialState,
);
