import { Markdown, Page } from 'components';
import newsList from 'mocks/news.yml';
import { compose, connect, PropTypes, React } from 'utils/create';

const SingleNewsPage = ({ news }) => (
  <Page>
    <h1>{news.title}</h1>

    <Markdown source={news.content} images={news.images} />
  </Page>
);

SingleNewsPage.propTypes = {
  news: PropTypes.object, // eslint-disable-line
};

SingleNewsPage.defaultProps = {
  news: {},
};

const mapStateToProps = (
  state,
  {
    match: {
      params: { slug },
    },
  },
) => ({ news: newsList.find(item => item.slug === slug) });

export default compose(connect(mapStateToProps))(SingleNewsPage);
