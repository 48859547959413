/* eslint-disable no-nested-ternary */

import { Content } from 'components';
import { Element } from 'react-scroll';
import { styled, PropTypes, React } from 'utils/create';
import { colors } from 'utils/variables';

const Section = ({ id, className, children, ...props }) => {
  console.log('props', props);
  return (
    <Element name={id} className={className}>
      <Content>{children}</Content>
    </Element>
  );
};

Section.propTypes = {
  className: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  id: PropTypes.string,
  light: PropTypes.bool,
};

Section.defaultProps = {
  id: null,
  light: false,
};

const StyledSection = styled(Section)(({ light, veryLight }) => ({
  overflow: 'hidden',
  padding: '2rem 0',
  backgroundColor: veryLight ? colors.text : light ? colors.gray : colors.darkGray,

  '& p:last-child': {
    margin: 0,
  },
}));

export default StyledSection;
