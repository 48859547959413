export default {
  defaultLanguage: process.env.REACT_APP_DEFAULT_LANGUAGE,
  onionful: {
    endpoint: process.env.REACT_APP_AUTH0_CLIENT_ID,
    space: process.env.REACT_APP_AUTH0_DOMAIN,
  },
  ui: {
    headerHeight: 64,
    headerHeightPx: '64px',
  },
  menu: [
    {
      key: 'main',
      path: '/',
    },
    {
      key: 'news',
      path: '/aktualnosci',
    },
    {
      key: 'research',
      path: '/badania',
    },
    {
      key: 'models',
      children: [
        {
          key: 'models-rdzen',
          path: '/modele3d/rdzen.html',
          external: true,
        },
        {
          key: 'models-topor',
          path: '/modele3d/topor.html',
          external: true,
        },
      ],
    },
    {
      key: 'walk',
      path: '/wirtualny-spacer/index.html',
      external: true,
    }
  ],
};
