import { media } from 'utils';
import { styled } from 'utils/create';

const ContentWrapper = styled.div({
  maxWidth: media.xl,
  margin: '0 auto',
  padding: '0 1rem',

  [media.query.mdAndUp]: {
    padding: '0 2rem',
  },
});

export default ContentWrapper;
